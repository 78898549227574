var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "q-pa-md",
      style: [{ height: _vm.tabHeight }, { "overflow-y": "auto" }],
    },
    [
      _c(
        "q-timeline",
        { staticClass: "moc-timeline", attrs: { color: "green-4" } },
        _vm._l(_vm.items, function (item, idx) {
          return _c(
            "q-timeline-entry",
            {
              key: idx,
              class: { "q-pb-xs": item.heading },
              attrs: { heading: item.heading, icon: item.icon },
              scopedSlots: _vm._u(
                [
                  item.title
                    ? {
                        key: "title",
                        fn: function () {
                          return [
                            _c("b", { staticClass: "text-blue-14" }, [
                              _vm._v("[" + _vm._s(item.title) + "]"),
                            ]),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  item.subtitle
                    ? {
                        key: "subtitle",
                        fn: function () {
                          return [_vm._v(" " + _vm._s(item.subtitle) + " ")]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              !item.heading
                ? _c("q-slide-transition", [
                    _c(
                      "div",
                      [
                        _c(item.component, {
                          tag: "component",
                          attrs: { param: _vm.param },
                          on: { setAssessmentName: _vm.setAssessmentName },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }